type BaseColors = "red" | "pink" | "orange" | "yellow" | "green" | "blue" | "purple" | "gray";

export type Colors = BaseColors | `light-${BaseColors}`;

export const COLOR = {
  green: "#008561",
  blue: "#3574d6",
  smokeGray: "#E0E5E3",
  primaryTypography: "#333333",
  white: "#ffffff",
  buttonHover: "#2661bd",
  darkGreen: "#15372C",
};
