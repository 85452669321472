import React from "react";
import classNames from "classnames";
import styles from "./icon_button.css";

import type { Attributes, ComponentType } from "react";

type Icon = ComponentType;

type Props = {
  icon: Icon;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "xs" | "sm" | "md";
  hoverColor?: "default" | "gray";
  tabIndex?: number;
};

export const links = () => [{ rel: "stylesheet", href: styles }];

export const IconButton = (props: Props) => {
  const { icon, onClick, size = "sm", label, hoverColor = "default", tabIndex } = props;

  const attributes = ({ size } as Attributes);

  const className = classNames({
    "icon-button": true,
    [`icon-button--${size}`]: true,
    [`icon-button--hover-${hoverColor}`]: hoverColor !== "default"
  });

  return (
    <button
      type="button"
      className={className}
      aria-label={label}
      tabIndex={tabIndex}
      onClick={onClick}>

      {React.createElement(icon, attributes)}
    </button>);

};